'use client';

import {useSupabase} from '~/shared/hooks/use-supabase';
import {Logo} from '@job-ish/blocks/components';
import {Anchor} from '@job-ish/ui/components';
import {IconArrowRight} from '@tabler/icons-react';
import NextLink from 'next/link';

import {HeaderNavigationMenu} from './navigation-menu';

export const Header = () => {
	const {user} = useSupabase();

	return (
		<header className="fixed left-0 top-0 z-20 w-dvw px-1.5 shadow-sm backdrop-blur-md">
			<div className="relative flex w-full justify-between px-2">
				<NextLink className="z-50 w-[180px]" href="/" tabIndex={-1}>
					<div className="absolute flex w-fit items-center justify-center gap-2 sm:justify-start">
						<Logo />
					</div>
				</NextLink>
				<HeaderNavigationMenu />
				<div className="hidden justify-end md:flex">
					<Anchor
						className="my-8 hidden text-sm font-semibold text-mauve11 visited:text-mauve11 md:flex"
						href="https://app.job-ish.com/auth/login"
						suffix={<IconArrowRight className="h-4 w-4" />}
					>
						{user ? 'Back to Board' : 'Log In'}
					</Anchor>
				</div>
			</div>
		</header>
	);
};
